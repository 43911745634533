@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
@value transitionIn: 1000ms;
@value transitionOut: 1000ms;
.car-page {
  position: relative;
  display: flex;
  padding: 8.3333333333vw 2.6041666667vw 2.6041666667vw;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
@media only screen and (max-width: 551px) {
  .car-page {
    padding: cacl(0)vw cacl(7.8125)vw cacl(19.375)vw;
  }
}
.car-page.appear, .car-page.enter {
  opacity: 0;
}
.car-page.appearing, .car-page.entering {
  opacity: 1;
  transition: opacity;
  transition-duration: transitionIn;
}
.car-page.exit {
  opacity: 1;
}
.car-page.exiting {
  opacity: 0;
  transition: opacity;
  transition-duration: transitionOut;
}
.car-page .asside {
  width: 33.6458333333vw;
}
.car-page .media {
  position: fixed;
  top: 2.6041666667vw;
  left: 2.6041666667vw;
  width: 61.1458333333vw;
  height: calc(100vh - 2.6041666667vw);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
@media only screen and (max-width: 551px) {
  .car-page .media {
    position: relative;
    width: 100%;
    height: cacl(118.125)vw;
  }
  .car-page .media::after {
    content: "";
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: cacl(15.3125)vw;
    border: calc(100vw - cacl(15.625)vw) solid transparent;
    border-bottom: cacl(15.3125)vw solid #fff;
    border-right: 0 solid transparent;
    border-top: 0 solid transparent;
  }
}

.slide-banner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
.slide-banner .inner {
  pointer-events: visiblePainted;
}