@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.items {
  display: flex;
  width: 100%;
  padding-bottom: 2.6041666667vw;
  justify-content: space-between;
}
@media only screen and (max-width: 551px) {
  .items {
    padding-bottom: cacl(7.8125)vw;
    flex-wrap: wrap;
  }
}
.items .item {
  position: relative;
  height: 24.6354166667vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.items .item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  background: -moz-linear-gradient(top, rgba(113, 113, 113, 0) 39%, rgba(90, 90, 90, 0.42) 66%, rgb(57, 57, 57) 100%);
  background: -webkit-linear-gradient(top, rgba(113, 113, 113, 0) 39%, rgba(90, 90, 90, 0.42) 66%, rgb(57, 57, 57) 100%);
  background: linear-gradient(to bottom, rgba(113, 113, 113, 0) 39%, rgba(90, 90, 90, 0.42) 66%, rgb(57, 57, 57) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#00717171", endColorstr="#393939", GradientType=0);
}
@media only screen and (max-width: 551px) {
  .items .item::after {
    display: none;
  }
}
.items .item .media-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: block;
  width: 100%;
  height: 100%;
}
.items .item .media {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.items .item:nth-child(1) {
  display: flex;
  width: 32.8125vw;
  align-items: flex-end;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(1) {
    position: relative;
    z-index: 2;
    width: 100%;
    height: cacl(118.125)vw;
    margin-bottom: 0;
  }
}
.items .item:nth-child(1)::after {
  z-index: 2;
  height: 32.96875vw;
  pointer-events: none;
}
.items .item:nth-child(1)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: block;
  height: 24.6354166667vw;
  border: 24.6354166667vw solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0 solid transparent;
  border-left: 2.5520833333vw solid #fff;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(1)::before {
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: cacl(15.3125)vw;
    border: calc(100vw - cacl(15.625)vw) solid transparent;
    border-bottom: cacl(15.3125)vw solid #fff;
    border-left: 0 solid transparent;
    border-top: 0 solid transparent;
  }
}
.items .item:nth-child(1) .item-info {
  position: relative;
  z-index: 3;
  display: flex;
  width: 100%;
  padding: 0 2.5vw 0 4.1145833333vw;
  flex-direction: row;
  justify-content: space-between;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(1) .item-info {
    position: absolute;
    right: cacl(7.8125)vw;
    bottom: cacl(13.4375)vw;
    padding: 0;
    flex-direction: column;
  }
}
.items .item:nth-child(1) .item-info a {
  text-decoration: none;
}
.items .item:nth-child(1) .info {
  padding-bottom: 2.6041666667vw;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(1) .info {
    padding-bottom: 0;
    order: 2;
  }
}
.items .item:nth-child(1) .rate {
  margin-top: 1.5625vw;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(1) .rate {
    margin-top: 0;
    order: 1;
  }
}
.items .item:nth-child(1) .item-category {
  margin-bottom: 1.1458333333vw;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(1) .item-category {
    margin-bottom: cacl(3.75)vw;
  }
}
.items .item:nth-child(1) .item-category span {
  display: inline-block;
  padding: 0 0.6770833333vw;
  font-size: 0.8333333333vw;
  line-height: 1.09375vw;
  letter-spacing: 0.0833333333vw;
  color: #fff;
  background-color: #f05;
  font-family: "almoni_black", sans-serif;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(1) .item-category span {
    padding: 0 cacl(2.5)vw;
    font-size: cacl(3.75)vw;
    line-height: cacl(4.6875)vw;
    letter-spacing: cacl(0.375)vw;
  }
}
.items .item:nth-child(1) .item-title {
  width: 10.4166666667vw;
  font-size: 2.34375vw;
  line-height: 1.8229166667vw;
  color: #fff;
  font-family: "almoni_black", sans-serif;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(1) .item-title {
    width: cacl(62.5)vw;
    font-size: cacl(10.9375)vw;
    line-height: cacl(7.8125)vw;
    letter-spacing: cacl(-0.21875)vw;
  }
}
.items .item:nth-child(2) {
  position: relative;
  z-index: 2;
  display: flex;
  width: 61.9791666667vw;
  align-items: flex-end;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(2) {
    z-index: 1;
    width: 100%;
    height: cacl(118.125)vw;
    order: 1;
  }
}
.items .item:nth-child(2)::after {
  z-index: 2;
  height: 21.6145833333vw;
  opacity: 0.69;
  pointer-events: none;
}
.items .item:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  display: block;
  height: 24.6354166667vw;
  border: 24.6354166667vw solid transparent;
  border-top: 0 solid transparent;
  border-right: 2.5520833333vw solid #fff;
  border-left: 0 solid transparent;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(2)::before {
    top: 0;
    bottom: auto;
    left: 0;
    width: 100%;
    height: cacl(15.3125)vw;
    border: calc(100vw - cacl(15.625)vw) solid transparent;
    border-bottom: 0 solid transparent;
    border-right: 0 solid transparent;
    border-top: cacl(15.3125)vw solid #fff;
  }
}
.items .item:nth-child(2) .item-info {
  position: relative;
  z-index: 3;
  display: flex;
  width: 100%;
  padding: 0 3.3333333333vw 0 1.6666666667vw;
  flex-direction: row;
  justify-content: space-between;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(2) .item-info {
    position: absolute;
    right: cacl(7.8125)vw;
    bottom: cacl(6.5625)vw;
    padding: 0;
    flex-direction: column;
  }
}
.items .item:nth-child(2) .item-info a {
  text-decoration: none;
}
.items .item:nth-child(2) .info {
  padding-bottom: 2.6041666667vw;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(2) .info {
    padding-bottom: 0;
    order: 2;
  }
}
.items .item:nth-child(2) .rate {
  margin-top: -0.5208333333vw;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(2) .rate {
    margin-top: 0;
    order: 1;
  }
}
.items .item:nth-child(2) .item-category {
  margin-bottom: 1.1458333333vw;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(2) .item-category {
    margin-bottom: cacl(3.75)vw;
  }
}
.items .item:nth-child(2) .item-category span {
  display: inline-block;
  padding: 0 0.6770833333vw;
  font-size: 0.8333333333vw;
  line-height: 1.09375vw;
  letter-spacing: 0.0833333333vw;
  color: #fff;
  background-color: #f05;
  font-family: "almoni_black", sans-serif;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(2) .item-category span {
    padding: 0 cacl(2.5)vw;
    font-size: cacl(3.75)vw;
    line-height: cacl(4.6875)vw;
    letter-spacing: cacl(0.375)vw;
  }
}
.items .item:nth-child(2) .item-title {
  font-size: 2.34375vw;
  line-height: 1.8229166667vw;
  color: #fff;
  font-family: "almoni_black", sans-serif;
}
@media only screen and (max-width: 551px) {
  .items .item:nth-child(2) .item-title {
    width: cacl(62.5)vw;
    font-size: cacl(10.9375)vw;
    line-height: cacl(7.8125)vw;
    letter-spacing: cacl(-0.21875)vw;
  }
}
.items .item .rate {
  position: relative;
  display: flex;
  width: 5.4166666667vw;
  height: 5.4166666667vw;
  padding: 0.4166666667vw;
  justify-content: center;
  align-items: center;
  border-radius: 2.7083333333vw;
  background-color: #fff;
  cursor: pointer;
}
@media only screen and (max-width: 551px) {
  .items .item .rate {
    display: inline-block;
    width: cacl(16.25)vw;
    height: cacl(16.25)vw;
    padding: cacl(1.25)vw;
    margin-bottom: cacl(4.6875)vw;
    border-radius: cacl(8.125)vw;
  }
}