@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.rate-block {
  position: relative;
  overflow: hidden;
}
.rate-block .rate-svg .path {
  opacity: 1;
  transition: 0.3s ease all;
}
.rate-block .rate-svg .path.show {
  opacity: 1;
}
.rate-block .rate-svg .path.hide {
  opacity: 0;
}
.rate-block.popUpRate .rate-svg .path.hide {
  opacity: 0;
}
.rate-block.big {
  width: 26.5625vw;
  height: 26.5625vw;
}
@media only screen and (max-width: 551px) {
  .rate-block.big {
    width: cacl(55.3125)vw;
    height: cacl(55.3125)vw;
  }
}
.rate-block.big .rate-image {
  width: 26.5625vw;
  height: 26.5625vw;
  font-size: 0;
}
@media only screen and (max-width: 551px) {
  .rate-block.big .rate-image {
    width: cacl(55.3125)vw;
    height: cacl(55.3125)vw;
  }
}
.rate-block.big .rate-image .rate-svg {
  width: 26.5625vw;
  height: 26.5625vw;
}
@media only screen and (max-width: 551px) {
  .rate-block.big .rate-image .rate-svg {
    width: cacl(55.3125)vw;
    height: cacl(55.3125)vw;
  }
}
.rate-block.big .rate-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10.1041666667vw;
  line-height: 4.3229166667vw;
  letter-spacing: -0.2020833333vw;
  font-family: "almoni_bold", sans-serif;
  white-space: nowrap;
  opacity: 0;
  transition: all ease 0.3s;
}
@media only screen and (max-width: 551px) {
  .rate-block.big .rate-number {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: cacl(22.1875)vw;
    line-height: cacl(9.6875)vw;
    letter-spacing: cacl(-1.115625)vw;
  }
}
.rate-block.big .rate-number.show {
  opacity: 1;
}
.rate-block.medium {
  width: 8.59375vw;
  height: 8.59375vw;
}
@media only screen and (max-width: 551px) {
  .rate-block.medium {
    width: cacl(16.875)vw;
    height: cacl(16.875)vw;
    margin-right: cacl(-5)vw;
  }
}
.rate-block.medium .rate-image {
  width: 8.59375vw;
  height: 8.59375vw;
  font-size: 0;
}
@media only screen and (max-width: 551px) {
  .rate-block.medium .rate-image {
    width: cacl(16.875)vw;
    height: cacl(16.875)vw;
  }
}
.rate-block.medium .rate-image .rate-svg {
  width: 8.59375vw;
  height: 8.59375vw;
}
@media only screen and (max-width: 551px) {
  .rate-block.medium .rate-image .rate-svg {
    width: cacl(16.875)vw;
    height: cacl(16.875)vw;
  }
}
.rate-block.medium .rate-number {
  position: absolute;
  top: 2.0833333333vw;
  left: 50%;
  transform: translate(-50%, 0%);
  font-size: 3.90625vw;
  line-height: 3.90625vw;
  letter-spacing: -0.234375vw;
  font-family: "almoni_bold", sans-serif;
  white-space: nowrap;
  opacity: 0;
  transition: all ease 0.3s;
}
@media only screen and (max-width: 551px) {
  .rate-block.medium .rate-number {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: cacl(7.5)vw;
    line-height: cacl(7.5)vw;
    letter-spacing: cacl(-0.625)vw;
    opacity: 1;
  }
}
.rate-block.medium .rate-number.show {
  opacity: 1;
}
.rate-block.medium .rate-title {
  position: absolute;
  top: 4.4791666667vw;
  right: 0;
  z-index: 2;
  width: 2.0833333333vw;
  font-size: 0.8333333333vw;
  line-height: 0.625vw;
  font-family: "almoni_bold", sans-serif;
  opacity: 0;
  transition: all ease 0.3s;
}
@media only screen and (max-width: 551px) {
  .rate-block.medium .rate-title {
    top: cacl(9.375)vw;
    width: 0.78125vw;
    font-size: cacl(1.875)vw;
    line-height: cacl(1.25)vw;
    letter-spacing: cacl(-0.078125)vw;
    opacity: 1;
  }
}
.rate-block.medium .rate-title.show {
  opacity: 1;
}
.rate-block.small {
  width: 4.53125vw;
  height: 4.53125vw;
}
.rate-block.small .rate-image {
  width: 4.53125vw;
  height: 4.53125vw;
  font-size: 0;
}
.rate-block.small .rate-image .rate-svg {
  width: 4.53125vw;
  height: 4.53125vw;
}
.rate-block.small .rate-number {
  position: absolute;
  top: 1.1979166667vw;
  left: 50%;
  transform: translate(-50%, 0%);
  font-size: 2.0833333333vw;
  line-height: 2.0833333333vw;
  letter-spacing: -0.1510416667vw;
  font-family: "almoni_bold", sans-serif;
  white-space: nowrap;
  opacity: 0;
  transition: all ease 0.3s;
}
.rate-block.small .rate-number.show {
  opacity: 1;
}
.rate-block.small .rate-title {
  position: absolute;
  top: 2.5vw;
  right: 0;
  width: 1.09375vw;
  font-size: 0.5729166667vw;
  line-height: 0.4166666667vw;
  letter-spacing: -0.06px;
  font-family: "almoni_bold", sans-serif;
  opacity: 0;
  transition: all ease 0.3s;
}
.rate-block.small .rate-title.show {
  opacity: 1;
}
@media only screen and (max-width: 551px) {
  .rate-block.mobile-small-type-one {
    width: cacl(13.75)vw;
  }
}
.rate-block.mobile-small-type-one .rate-image {
  display: block;
  width: 100%;
  height: cacl(13.75)vw;
}
@media only screen and (max-width: 551px) {
  .rate-block.mobile-small-type-one .rate-image .rate-svg {
    width: cacl(13.75)vw;
    height: cacl(13.75)vw;
  }
}
.rate-block.mobile-small-type-one .rate-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "almoni_bold", sans-serif;
  white-space: nowrap;
}
@media only screen and (max-width: 551px) {
  .rate-block.mobile-small-type-one .rate-number {
    font-size: cacl(6.875)vw;
    line-height: cacl(6.875)vw;
    letter-spacing: cacl(-0.5375)vw;
  }
}
.rate-block.mobile-small-type-one .rate-title {
  position: absolute;
  font-family: "almoni_bold", sans-serif;
}
@media only screen and (max-width: 551px) {
  .rate-block.mobile-small-type-one .rate-title {
    top: cacl(8.125)vw;
    width: 0.78125vw;
    font-size: cacl(1.875)vw;
    line-height: cacl(1.25)vw;
    letter-spacing: cacl(-0.078125)vw;
  }
}
@media only screen and (max-width: 551px) {
  .rate-block.mobile-small-type-two {
    width: cacl(11.875)vw;
  }
}
.rate-block.mobile-small-type-two .rate-image {
  display: block;
  width: 100%;
  height: cacl(11.875)vw;
}
@media only screen and (max-width: 551px) {
  .rate-block.mobile-small-type-two .rate-image .rate-svg {
    width: cacl(11.875)vw;
    height: cacl(11.875)vw;
  }
}
.rate-block.mobile-small-type-two .rate-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "almoni_bold", sans-serif;
  white-space: nowrap;
}
@media only screen and (max-width: 551px) {
  .rate-block.mobile-small-type-two .rate-number {
    font-size: cacl(5.625)vw;
    line-height: cacl(5.625)vw;
    letter-spacing: cacl(-0.46875)vw;
  }
}
.rate-block.mobile-small-type-two .rate-title {
  position: absolute;
  font-family: "almoni_bold", sans-serif;
}
@media only screen and (max-width: 551px) {
  .rate-block.mobile-small-type-two .rate-title {
    top: cacl(6.875)vw;
    width: 0.5208333333vw;
    font-size: cacl(1.5625)vw;
    line-height: cacl(1.25)vw;
    letter-spacing: cacl(-0.078125)vw;
  }
}
.rate-block .svg-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 101%;
  height: 101%;
  transform: translate(-50%, -50%) rotateY(-180deg);
  transition: 0.3s ease all;
}
.rate-block .svg-overlay.hide-overlay {
  opacity: 0;
}
.rate-block .svg-overlay svg {
  display: block;
  width: 100%;
  height: 100%;
}
.rate-block .svg-overlay svg path {
  display: block;
  width: 100%;
  height: 100%;
}

.cls {
  fill: #002adb;
}