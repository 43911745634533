@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
:global .slick-slider {
  width: 61.1458333333vw;
  height: calc(100vh - 2.6041666667vw);
}
@media only screen and (max-width: 551px) {
  :global .slick-slider {
    width: 100%;
    height: cacl(118.125)vw;
  }
}
:global .slick-slider .slick-dots {
  bottom: 1.4583333333vw;
}
@media only screen and (max-width: 551px) {
  :global .slick-slider .slick-dots {
    bottom: cacl(13.4375)vw;
    z-index: 3;
  }
}
:global .slick-slider .slick-dots li {
  width: 1.4583333333vw;
  height: 0.78125vw;
  margin: 0;
}
@media only screen and (max-width: 551px) {
  :global .slick-slider .slick-dots li {
    width: cacl(2.5)vw;
    height: cacl(2.5)vw;
    padding: 0 cacl(2.5)vw;
  }
}
:global .slick-slider .slick-dots li button {
  width: 2.0833333333vw;
  height: 0.78125vw;
  padding: 0.1041666667vw 0.5208333333vw;
}
@media only screen and (max-width: 551px) {
  :global .slick-slider .slick-dots li button {
    position: relative;
    width: cacl(2.5)vw;
    height: cacl(2.5)vw;
    padding: cacl(0)vw cacl(1.5625)vw;
  }
}
:global .slick-slider .slick-dots li button::before {
  color: #fff;
  opacity: 0.7;
  line-height: 0.78125vw;
  font-size: 0.78125vw;
  transition: all ease 0.3s;
}
@media only screen and (max-width: 551px) {
  :global .slick-slider .slick-dots li button::before {
    width: cacl(2.5)vw;
    height: cacl(2.5)vw;
    line-height: cacl(2.5)vw;
    font-size: cacl(2.5)vw;
  }
}
:global .slick-slider .slick-dots li.slick-active button::before {
  color: #fff;
  opacity: 1;
}
:global .slick-slider .slick-dots li:hover button::before {
  color: #fff;
}
:global .slick-list {
  height: calc(100vh - 2.6041666667vw);
}
:global .slick-slide {
  height: calc(100vh - 2.6041666667vw);
}

.slider-wrapper {
  position: fixed;
  top: 2.6041666667vw;
  left: 2.6041666667vw;
  width: 61.1458333333vw;
  height: calc(100vh - 2.6041666667vw);
}
@media only screen and (max-width: 551px) {
  .slider-wrapper {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    height: cacl(118.125)vw;
    order: 1;
  }
  .slider-wrapper::after {
    content: "";
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: cacl(15.3125)vw;
    border: calc(100vw - cacl(15.625)vw) solid transparent;
    border-bottom: cacl(15.3125)vw solid #fff;
    border-right: 0 solid transparent;
    border-top: 0 solid transparent;
  }
}
.slider-wrapper .slide {
  width: 61.1458333333vw;
  height: calc(100vh - 2.6041666667vw);
}
@media only screen and (max-width: 551px) {
  .slider-wrapper .slide {
    width: 100%;
    height: cacl(118.125)vw;
  }
}
.slider-wrapper .slide .slide-inner {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.slider-wrapper .slide .slide-inner .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7.4479166667vw;
  height: 7.4479166667vw;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.slider-wrapper .slide .slide-inner .play-btn img {
  display: block;
  width: 100%;
}
.slider-wrapper .custom-buttons {
  position: absolute;
  top: 50%;
  left: 0;
  display: flex;
  width: 100%;
  height: 5.7291666667vw;
  justify-content: space-between;
  transform: translate(0, -50%);
  pointer-events: none;
}
@media only screen and (max-width: 551px) {
  .slider-wrapper .custom-buttons {
    height: cacl(17.1875)vw;
  }
}
.slider-wrapper .custom-buttons .next-btn {
  margin-right: 1.1458333333vw;
  background-image: url("/assets/client/arrow-left.png");
}
@media only screen and (max-width: 551px) {
  .slider-wrapper .custom-buttons .next-btn {
    margin-right: cacl(3.75)vw;
  }
}
.slider-wrapper .custom-buttons .prev-btn {
  background-image: url("/assets/client/arrow-right.png");
}
.slider-wrapper .custom-buttons .next-btn,
.slider-wrapper .custom-buttons .prev-btn {
  position: relative;
  width: 3.4375vw;
  height: 5.7291666667vw;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all ease 0.3s;
  pointer-events: all;
}
@media only screen and (max-width: 551px) {
  .slider-wrapper .custom-buttons .next-btn,
  .slider-wrapper .custom-buttons .prev-btn {
    width: cacl(7.8125)vw;
    height: cacl(14.6875)vw;
    opacity: 1;
  }
}
.slider-wrapper .custom-buttons .next-btn:hover,
.slider-wrapper .custom-buttons .prev-btn:hover {
  opacity: 1;
}
.slider-wrapper .custom-buttons .prev-visible {
  visibility: visible;
  opacity: 0.8;
}
.slider-wrapper .custom-buttons .next-visible {
  visibility: visible;
  opacity: 0.8;
}