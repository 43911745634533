.popUp {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 600;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s ease, visibility 0.35s ease;
}
.popUp.visible {
  height: 100vh;
  max-height: 100vh;
  opacity: 1;
  visibility: visible;
}