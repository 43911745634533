@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.asside {
  width: 33.6458333333vw;
  padding-top: 4.2708333333vw;
  padding-left: 2.6041666667vw;
}
@media only screen and (max-width: 551px) {
  .asside {
    z-index: 2;
    width: 100%;
    padding-top: 0;
    padding-left: 0;
    margin-top: cacl(-7.8125)vw;
    order: 2;
  }
}
.asside .additional-links {
  display: none;
}
@media only screen and (max-width: 551px) {
  .asside .additional-links {
    display: block;
    width: 100%;
    padding: cacl(2.8125)vw cacl(4.0625)vw cacl(2.8125)vw cacl(4.0625)vw;
    margin-top: cacl(10.3125)vw;
    border: solid 1px #f05;
  }
  .asside .additional-links p {
    font-size: cacl(2.96875)vw;
    line-height: cacl(3.75)vw;
    font-family: "almoni_bold", sans-serif;
    color: #000;
    text-align: center;
  }
  .asside .additional-links p a {
    color: #f05;
    text-decoration: underline;
  }
  .asside .additional-links p a:hover {
    text-decoration: none;
  }
}