@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.items {
  width: 100%;
  overflow: hidden;
}
.items .items_i {
  display: flex;
  margin-right: -1%;
  margin-left: -1%;
  flex-wrap: wrap;
}
@media only screen and (max-width: 551px) {
  .items .items_i {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.items .item {
  width: 23%;
  padding-bottom: 2.7083333333vw;
  margin: 0 1%;
}
@media only screen and (max-width: 551px) {
  .items .item {
    width: 46%;
    padding-bottom: cacl(8.125)vw;
    margin: 0;
  }
}
@media only screen and (max-width: 551px) {
  .items .item.single-item {
    width: 100%;
  }
}
@media only screen and (max-width: 551px) {
  .items .item.single-item .image-wrapper {
    height: cacl(52.1875)vw;
    margin-bottom: cacl(4.6875)vw;
  }
}
@media only screen and (max-width: 551px) {
  .items .item.single-item .item-info .rate {
    width: cacl(16.875)vw;
    height: cacl(16.875)vw;
  }
}
@media only screen and (max-width: 551px) {
  .items .item.single-item .item-info .info {
    padding-left: cacl(31.25)vw;
  }
}
@media only screen and (max-width: 551px) {
  .items .item.single-item .item-info .info .item-category {
    margin-bottom: cacl(2.1875)vw;
  }
}
@media only screen and (max-width: 551px) {
  .items .item.single-item .item-info .info .item-category span {
    padding: 0 cacl(2.5)vw;
    font-size: cacl(3.125)vw;
    line-height: cacl(4.375)vw;
    letter-spacing: cacl(0.3125)vw;
  }
}
@media only screen and (max-width: 551px) {
  .items .item.single-item .item-info .info .item-title {
    font-size: cacl(6.875)vw;
    line-height: cacl(4.6875)vw;
    letter-spacing: cacl(-0.1375)vw;
  }
}
.items .image-wrapper {
  position: relative;
  width: 100%;
  height: 13.5416666667vw;
  margin-bottom: 0.9895833333vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
@media only screen and (max-width: 551px) {
  .items .image-wrapper {
    height: cacl(24.0625)vw;
    margin-bottom: cacl(1.875)vw;
  }
}
.items .image-wrapper::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6.7708333333vw;
  background: -moz-linear-gradient(top, rgba(113, 113, 113, 0) 39%, rgba(90, 90, 90, 0.42) 66%, rgb(57, 57, 57) 100%);
  background: -webkit-linear-gradient(top, rgba(113, 113, 113, 0) 39%, rgba(90, 90, 90, 0.42) 66%, rgb(57, 57, 57) 100%);
  background: linear-gradient(to bottom, rgba(113, 113, 113, 0) 39%, rgba(90, 90, 90, 0.42) 66%, rgb(57, 57, 57) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#00717171", endColorstr="#393939", GradientType=0);
}
@media only screen and (max-width: 551px) {
  .items .image-wrapper::after {
    display: none;
  }
}
.items .item-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.items .item-info .rate {
  position: relative;
  width: 4.53125vw;
  height: 4.53125vw;
  flex-shrink: 0;
  cursor: pointer;
}
@media only screen and (max-width: 551px) {
  .items .item-info .rate {
    width: cacl(11.875)vw;
    height: cacl(11.875)vw;
  }
}
.items .item-info .info {
  padding-left: 6.25vw;
  flex-grow: 1;
}
@media only screen and (max-width: 551px) {
  .items .item-info .info {
    padding-left: cacl(3.125)vw;
  }
}
.items .item-info .info a {
  text-decoration: none;
}
.items .item-info .info .item-category {
  margin-bottom: 0.6770833333vw;
}
@media only screen and (max-width: 551px) {
  .items .item-info .info .item-category {
    margin-bottom: cacl(1.25)vw;
  }
}
.items .item-info .info .item-category span {
  display: inline-block;
  padding: 0 0.6770833333vw;
  font-size: 0.9375vw;
  line-height: 1.09375vw;
  letter-spacing: 0.0833333333vw;
  color: #fff;
  background-color: #f05;
  font-family: "almoni_black", sans-serif;
}
@media only screen and (max-width: 551px) {
  .items .item-info .info .item-category span {
    padding: 0 cacl(1.5625)vw;
    font-size: cacl(1.875)vw;
    line-height: cacl(3.125)vw;
    letter-spacing: cacl(0.171875)vw;
  }
}
.items .item-info .info .item-title {
  font-size: 1.8229166667vw;
  line-height: 1.3020833333vw;
  letter-spacing: -0.0364583333vw;
  font-family: "almoni_black", sans-serif;
  color: #000;
}
@media only screen and (max-width: 551px) {
  .items .item-info .info .item-title {
    font-size: cacl(4.0625)vw;
    line-height: cacl(3.4375)vw;
    letter-spacing: cacl(-0.08125)vw;
  }
}