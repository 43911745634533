@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.searchForm {
  z-index: 1;
  width: 0;
  padding-bottom: 2.6041666667vw;
  background-color: white;
  visibility: hidden;
}
@media only screen and (max-width: 551px) {
  .searchForm {
    width: 100%;
    padding-bottom: cacl(1.5625)vw;
  }
}
.searchForm.show {
  width: 100%;
  visibility: visible;
  transition: width, visibility, 1.5s, easy-in-out;
}
@media only screen and (max-width: 551px) {
  .searchForm.show {
    transition-delay: 0s, 0s;
  }
}

.searchField {
  position: relative;
  width: 100%;
  padding: 0.2604166667vw 2.3958333333vw 0 2.03125vw;
}
@media only screen and (max-width: 551px) {
  .searchField {
    padding: 0;
    margin: cacl(7.1875)vw 0 0;
  }
}
.searchField input {
  width: inherit;
  height: 3.125vw;
  padding: 0 1.40625vw 0 2.6041666667vw;
  border-color: #dfdfdf;
  border: solid 2px #ececec;
  border-radius: 2.0833333333vw;
  outline: 0;
  font-family: "Heebo", sans-serif;
  font-size: 0.9375vw;
}
@media only screen and (max-width: 551px) {
  .searchField input {
    height: cacl(9.375)vw;
    padding: 0 cacl(4.6875)vw 0 cacl(4.0625)vw;
    border-radius: cacl(4.6875)vw;
    font-size: cacl(2.8125)vw;
  }
}
.searchField input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "Heebo", sans-serif;
  color: #aaa;
  font-size: 0.9375vw;
}
@media only screen and (max-width: 551px) {
  .searchField input::-webkit-input-placeholder {
    font-size: cacl(2.8125)vw;
  }
}
.searchField input::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Heebo", sans-serif;
  color: #aaa;
  font-size: 0.9375vw;
}
@media only screen and (max-width: 551px) {
  .searchField input::-moz-placeholder {
    font-size: cacl(2.8125)vw;
  }
}
.searchField input:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "Heebo", sans-serif;
  color: #aaa;
  font-size: 0.9375vw;
}
@media only screen and (max-width: 551px) {
  .searchField input:-ms-input-placeholder {
    font-size: cacl(2.8125)vw;
  }
}
.searchField input:-moz-placeholder {
  /* Firefox 18- */
  font-family: "Heebo", sans-serif;
  color: #aaa;
  font-size: 0.9375vw;
}
@media only screen and (max-width: 551px) {
  .searchField input:-moz-placeholder {
    font-size: cacl(2.8125)vw;
  }
}
.searchField input:-internal-autofill-selected, .searchField input:-webkit-autofill, .searchField input:-webkit-autofill:hover, .searchField input:-webkit-autofill:focus, .searchField input:-webkit-autofill:active {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
  box-shadow: 0 0 0 30px transparent inset !important;
  background-color: transparent !important;
}
.searchField .searchIcon,
.searchField .arrowLeftIcon {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.searchField .searchIcon.show,
.searchField .arrowLeftIcon.show {
  opacity: 1;
}
.searchField .searchIcon {
  position: absolute;
  top: 1.09375vw;
  left: 3.4375vw;
}
@media only screen and (min-width: 1025px) {
  .searchField .searchIcon.show {
    transition-delay: 0.5s;
  }
}
@media only screen and (max-width: 551px) {
  .searchField .searchIcon {
    display: none;
  }
}
.searchField .searchIcon svg {
  width: 1.4583333333vw;
  height: 1.4583333333vw;
}
.searchField .arrowLeftIcon {
  position: absolute;
  top: 1.1979166667vw;
  left: 3.4375vw;
}
@media only screen and (max-width: 551px) {
  .searchField .arrowLeftIcon {
    top: cacl(2.8125)vw;
    left: cacl(3.75)vw;
  }
}
.searchField .arrowLeftIcon svg {
  width: 1.25vw;
  height: 1.1979166667vw;
}
@media only screen and (max-width: 551px) {
  .searchField .arrowLeftIcon svg {
    width: cacl(3.78125)vw;
    height: cacl(3.6875)vw;
  }
}
.searchField .arrowLeftIcon:hover {
  cursor: pointer;
}
.searchField .arrowLeftIcon:hover svg {
  path-fill: black;
}

.sugestions {
  position: absolute;
  top: 1.5625vw;
  right: 0;
  z-index: -1;
  width: inherit;
}
@media only screen and (max-width: 551px) {
  .sugestions {
    top: cacl(-3.125)vw;
  }
}
.sugestions .sugestionsList {
  padding: 2.0833333333vw 1.5625vw 0.4166666667vw;
  margin: 0 2.3958333333vw 0 2.03125vw;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 10px 29px 0 rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 551px) {
  .sugestions .sugestionsList {
    padding: cacl(6.25)vw cacl(4.6875)vw 0 cacl(4.0625)vw;
    margin: cacl(6.25)vw 0 0;
  }
}
.sugestions .sugestionsList .sugestionItem {
  padding: 0.5208333333vw 0;
  font-family: "Heebo", sans-serif;
  font-size: 0.9375vw;
  line-height: 1.2;
}
@media only screen and (max-width: 551px) {
  .sugestions .sugestionsList .sugestionItem {
    padding: cacl(1.5625)vw 0;
    font-size: cacl(2.8125)vw;
  }
}
.sugestions .highlight {
  color: #f05;
}