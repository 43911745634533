@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
@value transitionIn: 1000ms;
@value transitionOut: 1000ms;
.searchPage {
  position: relative;
  display: flex;
  min-height: calc(100vh - 200px);
  padding: 8.3333333333vw 2.6041666667vw 2.6041666667vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 551px) {
  .searchPage {
    min-height: 100vh;
    padding: 0 cacl(7.8125)vw cacl(15.625)vw;
    justify-content: flex-start;
  }
}
.searchPage.appear, .searchPage.enter {
  opacity: 0;
}
.searchPage.appearing, .searchPage.entering {
  opacity: 1;
  transition: opacity;
  transition-duration: transitionIn;
}
.searchPage.exit {
  opacity: 1;
}
.searchPage.exiting {
  opacity: 0;
  transition: opacity;
  transition-duration: transitionOut;
}

@media only screen and (max-width: 551px) {
  .header {
    position: absolute;
    width: 100%;
  }
}

.top {
  position: relative;
  display: flex;
  width: 100%;
  padding: 1.875vw 0 2.5vw;
  justify-content: space-between;
  align-items: flex-end;
}
@media only screen and (max-width: 551px) {
  .top {
    padding: cacl(1.25)vw 0 cacl(5.625)vw;
  }
}
.top .searchPhrase {
  display: flex;
  padding-bottom: 0.5729166667vw;
}
.top .searchPhrase .title {
  position: relative;
  margin-left: 2.9166666667vw;
  font-family: "almoni_regular", sans-serif;
  font-size: 1.8229166667vw;
  line-height: 0.91;
  letter-spacing: 0.0911458333vw;
}
@media only screen and (max-width: 551px) {
  .top .searchPhrase .title {
    margin-left: cacl(7.8125)vw;
    font-size: cacl(4.6875)vw;
    letter-spacing: cacl(0.234375)vw;
  }
}
.top .searchPhrase .title::after {
  content: ">>";
  position: absolute;
  top: 0vw;
  left: -2.2916666667vw;
  color: #f05;
}
@media only screen and (max-width: 551px) {
  .top .searchPhrase .title::after {
    left: cacl(-6.875)vw;
  }
}
.top .searchPhrase .phrase {
  padding-bottom: 0.5729166667vw;
  font-family: "almoni_black", sans-serif;
  font-size: 1.8229166667vw;
  line-height: 0.91;
  letter-spacing: 0.0911458333vw;
}
@media only screen and (max-width: 551px) {
  .top .searchPhrase .phrase {
    font-size: cacl(4.6875)vw;
    letter-spacing: cacl(0.234375)vw;
  }
}
.top .filtersTrigger {
  font-family: "almoni_bold", sans-serif;
  font-size: 1.25vw;
  line-height: 1;
  letter-spacing: 0.125vw;
  color: #aaa;
  transition: 0.3s;
}
.top .filtersTrigger:hover {
  color: #f05;
  cursor: pointer;
}

.content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.content .searchResults {
  width: 70.4166666667vw;
}
@media only screen and (max-width: 551px) {
  .content .searchResults {
    width: 100%;
  }
}
.content aside {
  display: none;
  width: 21.7708333333vw;
  background-color: rgba(90, 90, 90, 0.2);
}
@media only screen and (max-width: 551px) {
  .content aside {
    display: none;
  }
}

.resultsGrid {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.item {
  position: relative;
  width: 21.7708333333vw;
  margin-bottom: 2.65625vw;
  margin-left: 2.5520833333vw;
}
@media only screen and (max-width: 551px) {
  .item {
    width: cacl(38.75)vw;
    margin-bottom: cacl(3.125)vw;
    margin-left: cacl(6.875)vw;
  }
  .item:nth-child(1), .item:nth-child(2n-1) {
    margin-left: 0;
  }
}
@media only screen and (min-width: 1025px) {
  .item:nth-child(1), .item:nth-child(3n-2) {
    margin-left: 0;
  }
}
.item .image {
  position: relative;
  display: inline-block;
  height: 13.5416666667vw;
}
@media only screen and (max-width: 551px) {
  .item .image {
    height: cacl(24.375)vw;
  }
}
.item .image::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 7.2916666667vw;
  background: -moz-linear-gradient(top, rgba(113, 113, 113, 0) 39%, rgba(90, 90, 90, 0.42) 66%, rgb(57, 57, 57) 100%);
  background: -webkit-linear-gradient(top, rgba(113, 113, 113, 0) 39%, rgba(90, 90, 90, 0.42) 66%, rgb(57, 57, 57) 100%);
  background: linear-gradient(to bottom, rgba(113, 113, 113, 0) 39%, rgba(90, 90, 90, 0.42) 66%, rgb(57, 57, 57) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#00717171", endColorstr="#393939", GradientType=0);
}
.item .image img {
  width: 100%;
  height: 100%;
}
.item .info {
  display: flex;
  padding-top: 0.8333333333vw;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.item .info .itemInfo .itemCategory {
  margin-bottom: 0.6770833333vw;
}
.item .info .itemInfo .itemCategory span {
  display: inline-block;
  padding: 0 0.6770833333vw;
  font-size: 0.8333333333vw;
  line-height: 1.09375vw;
  letter-spacing: 0.0833333333vw;
  color: #fff;
  background-color: #f05;
  font-family: "almoni_black", sans-serif;
}
@media only screen and (max-width: 551px) {
  .item .info .itemInfo .itemCategory span {
    font-size: cacl(1.71875)vw;
    line-height: cacl(3.125)vw;
    letter-spacing: cacl(0.171875)vw;
  }
}
.item .info .itemInfo .itemTitle {
  width: 80%;
  font-size: 1.8229166667vw;
  line-height: 1.3020833333vw;
  letter-spacing: -0.0364583333vw;
  font-family: "almoni_black", sans-serif;
}
@media only screen and (max-width: 551px) {
  .item .info .itemInfo .itemTitle {
    font-size: cacl(4.0625)vw;
    line-height: cacl(3.4375)vw;
    letter-spacing: cacl(-0.1625)vw;
  }
}
.item .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 6.40625vw;
  height: 6.40625vw;
  overflow: hidden;
  transform: translate(-50%, -50%);
  border-radius: 3.2291666667vw;
  cursor: pointer;
  transition: all 0.3s ease;
}
.item .play-btn:hover {
  transform: translate(-50%, -50%) scale(1.1);
}
.item .play-btn img {
  display: block;
  width: 100%;
}

.firstItem {
  position: relative;
  width: 100%;
  height: 38.3333333333vw;
}
@media only screen and (max-width: 551px) {
  .firstItem {
    height: cacl(103.125)vw;
    margin-bottom: cacl(7.8125)vw;
  }
}
.firstItem .image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 38.3333333333vw;
}
@media only screen and (max-width: 551px) {
  .firstItem .image {
    height: cacl(103.125)vw;
    overflow: hidden;
  }
  .firstItem .image img {
    width: auto;
    height: 100%;
  }
}
.firstItem .image::after {
  height: 24.8958333333vw;
}
@media only screen and (max-width: 551px) {
  .firstItem .image::after {
    height: cacl(71.875)vw;
  }
}
.firstItem .info {
  position: absolute;
  right: 2.4479166667vw;
  bottom: 0;
  z-index: 2;
  display: block;
  padding-bottom: 2.34375vw;
}
@media only screen and (max-width: 551px) {
  .firstItem .info {
    right: cacl(5.3125)vw;
    left: cacl(1.5625)vw;
    padding-bottom: cacl(5.3125)vw;
  }
}
.firstItem .info .rate {
  display: flex;
  width: 9.7916666667vw;
  height: 9.7916666667vw;
  margin-bottom: 25px;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 100%;
}
@media only screen and (max-width: 551px) {
  .firstItem .info .rate {
    width: cacl(16.875)vw;
    height: cacl(16.875)vw;
    margin-bottom: cacl(3.75)vw;
  }
}
.firstItem .info .itemInfo .itemCategory {
  margin-bottom: 1.25vw;
}
@media only screen and (max-width: 551px) {
  .firstItem .info .itemInfo .itemCategory {
    margin-bottom: cacl(5)vw;
  }
}
.firstItem .info .itemInfo .itemCategory span {
  display: inline-block;
  padding: 0 0.9375vw;
  font-size: 1.25vw;
  line-height: 1.5625vw;
  letter-spacing: 0.125vw;
  color: #fff;
  background-color: #f05;
  font-family: "almoni_black", sans-serif;
}
@media only screen and (max-width: 551px) {
  .firstItem .info .itemInfo .itemCategory span {
    padding: 0 cacl(2.1875)vw;
    font-size: cacl(3.75)vw;
    line-height: cacl(4.6875)vw;
    letter-spacing: cacl(0.375)vw;
  }
}
.firstItem .info .itemInfo .itemTitle {
  width: 26.0416666667vw;
}
@media only screen and (max-width: 551px) {
  .firstItem .info .itemInfo .itemTitle {
    width: 100%;
  }
}
.firstItem .info .itemInfo .itemTitle span {
  font-family: "almoni_black", sans-serif;
  font-size: 4.7395833333vw;
  line-height: 3.1770833333vw;
  letter-spacing: -0.0947916667vw;
  color: #fff;
}
@media only screen and (max-width: 551px) {
  .firstItem .info .itemInfo .itemTitle span {
    font-size: cacl(10.9375)vw;
    line-height: 0.71;
    letter-spacing: cacl(-0.21875)vw;
    color: #000;
  }
}

.noMatch {
  display: flex;
  max-width: 28.4375vw;
  flex-direction: column;
  align-content: center;
}
@media only screen and (max-width: 551px) {
  .noMatch {
    max-width: cacl(70.9375)vw;
    min-height: calc(100vh - 140px);
    padding-top: cacl(25.625)vw;
  }
}
.noMatch .topText,
.noMatch .bottomText {
  font-family: "almoni_bold", sans-serif;
  font-size: 2.5vw;
  line-height: 0.88;
  letter-spacing: -0.96px;
  color: #000;
  text-align: center;
}
@media only screen and (max-width: 551px) {
  .noMatch .topText,
  .noMatch .bottomText {
    font-size: cacl(6.25)vw;
    line-height: cacl(5.3125)vw;
  }
}
.noMatch .topText {
  padding-top: 0.4166666667vw;
  padding-bottom: 2.6041666667vw;
  white-space: pre-line;
}
@media only screen and (max-width: 551px) {
  .noMatch .topText {
    padding-top: cacl(4.375)vw;
    padding-bottom: cacl(6.25)vw;
  }
}
.noMatch .bottomText {
  color: #f05;
}
@media only screen and (max-width: 551px) {
  .noMatch img {
    display: block;
    width: 100%;
  }
}

.similarItems {
  width: 100%;
}
.similarItems .title {
  padding: 0.15625vw 0 2.4479166667vw;
  font-family: "almoni_black", sans-serif;
  font-size: 3.125vw;
  line-height: 0.57;
  color: #f05;
}
@media only screen and (max-width: 551px) {
  .similarItems .title {
    padding: cacl(9.375)vw 0 cacl(7.8125)vw;
    font-size: cacl(6.25)vw;
    color: #000;
  }
}
.similarItems .similarItem {
  position: relative;
  display: flex;
  padding-bottom: 2.3958333333vw;
  align-items: center;
}
@media only screen and (max-width: 551px) {
  .similarItems .similarItem {
    padding-top: cacl(3.4375)vw;
    padding-bottom: cacl(4.0625)vw;
  }
}
.similarItems .similarItem::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #aaa;
}
.similarItems .similarItem:last-child::after {
  display: none;
}
.similarItems .similarItem .image {
  display: inline-block;
  width: 21.7708333333vw;
  height: 12.1875vw;
}
@media only screen and (max-width: 551px) {
  .similarItems .similarItem .image {
    width: cacl(25.9375)vw;
    height: cacl(17.1875)vw;
  }
}
.similarItems .similarItem .image img {
  display: inline-block;
  width: inherit;
  height: inherit;
  max-width: 21.7708333333vw;
  max-height: 12.1875vw;
}
@media only screen and (max-width: 551px) {
  .similarItems .similarItem .image img {
    max-width: cacl(25.9375)vw;
    max-height: cacl(17.1875)vw;
  }
}
.similarItems .similarItem .info {
  display: flex;
  width: 100%;
  padding: 0 3.125vw 0 2.8645833333vw;
  align-items: center;
}
@media only screen and (max-width: 551px) {
  .similarItems .similarItem .info {
    padding: 0 cacl(5.625)vw 0 cacl(1.5625)vw;
  }
}
.similarItems .similarItem .info .rate {
  margin-top: -0.4166666667vw;
}
@media only screen and (max-width: 551px) {
  .similarItems .similarItem .info .rate {
    margin-top: cacl(-0.625)vw;
  }
}
.similarItems .similarItem .itemInfo {
  width: 100%;
  padding-top: 1.8229166667vw;
  padding-right: 3.28125vw;
}
@media only screen and (max-width: 551px) {
  .similarItems .similarItem .itemInfo {
    max-width: cacl(74.6875)vw;
    padding-top: 0;
    padding-right: cacl(3.4375)vw;
  }
}
.similarItems .similarItem .itemInfo .itemTitle {
  display: inline-block;
  padding-bottom: 0.2604166667vw;
  font-family: "almoni_black", sans-serif;
  font-size: 2.0833333333vw;
  line-height: 1.5625vw;
  color: black;
  text-decoration: none;
  transition: 0.2s;
}
@media only screen and (max-width: 551px) {
  .similarItems .similarItem .itemInfo .itemTitle {
    padding-bottom: cacl(1.5625)vw;
    font-size: cacl(4.6875)vw;
    line-height: cacl(3.75)vw;
  }
}
.similarItems .similarItem .itemInfo .itemTitle:hover {
  color: #f05;
}
.similarItems .similarItem .itemInfo .itemDescription {
  width: 15.5208333333vw;
  font-family: "Assistant", sans-serif;
  font-size: 1.0416666667vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6666666667vw;
  color: black;
}
@media only screen and (max-width: 551px) {
  .similarItems .similarItem .itemInfo .itemDescription {
    width: auto;
    max-width: cacl(37.1875)vw;
    padding-top: cacl(0)vw;
    overflow: hidden;
    font-size: cacl(2.5)vw;
    line-height: 1.2;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.similarItems .similarItem .itemInfo .itemDescription p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media only screen and (max-width: 551px) {
  .similarItems .similarItem .itemInfo .category {
    font-family: "almoni_black", sans-serif;
    font-size: cacl(2.5)vw;
    line-height: cacl(6.5625)vw;
    letter-spacing: cacl(0.125)vw;
    color: #f05;
  }
}
.similarItems .similarItem .itemInfo .itemOtherInfo {
  padding-top: 0.3125vw;
  font-family: "Assistant", sans-serif;
  font-size: 0.8333333333vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: right;
  color: #c5c5c5;
}
@media only screen and (max-width: 551px) {
  .similarItems .similarItem .itemInfo .itemOtherInfo {
    display: none;
  }
}
.similarItems .similarItem .itemInfo .itemOtherInfo span {
  position: relative;
  padding-left: 0.8333333333vw;
}
.similarItems .similarItem .itemInfo .itemOtherInfo span::after {
  content: "|";
  position: absolute;
  left: 6px;
  font-family: "Assistant", sans-serif;
  font-size: 0.8333333333vw;
  color: #c5c5c5;
}
.similarItems .similarItem .itemInfo .itemOtherInfo span.data::after {
  content: "";
}
.similarItems .similarItem .arrowsLeft {
  margin: 1.40625vw 0 0;
}
@media only screen and (max-width: 551px) {
  .similarItems .similarItem .arrowsLeft {
    display: none;
  }
}