@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.rate-pop-up {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 25px;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
}
@media only screen and (max-width: 551px) {
  .rate-pop-up {
    padding: 0;
  }
}

.content {
  position: relative;
  display: flex;
  width: 64.8958333333vw;
  height: 36.5104166667vw;
  padding: 5.8854166667vw 4.2708333333vw 6.4583333333vw 5.0520833333vw;
  flex-flow: column nowrap;
  border-radius: 0;
  background-color: white;
}
@media only screen and (max-width: 551px) {
  .content {
    width: 100%;
    height: 100%;
    padding: cacl(28.125)vw cacl(7.8125)vw 0;
  }
}
.content .close-btn {
  position: absolute;
  top: 1.6666666667vw;
  right: 1.484375vw;
  width: 1.796875vw;
  height: 1.796875vw;
}
@media only screen and (max-width: 551px) {
  .content .close-btn {
    top: cacl(11.5625)vw;
    right: auto;
    left: cacl(7.8125)vw;
    width: cacl(5.375)vw;
    height: cacl(5.375)vw;
  }
}
.content .close-btn::before, .content .close-btn::after {
  content: "";
  position: absolute;
  width: 2.2916666667vw;
  height: 0.2083333333vw;
  background-color: #aaa;
}
@media only screen and (max-width: 551px) {
  .content .close-btn::before, .content .close-btn::after {
    width: cacl(6.875)vw;
    height: cacl(0.625)vw;
  }
}
.content .close-btn::before {
  top: 0.8333333333vw;
  left: -0.2604166667vw;
  transform: rotate(45deg);
}
@media only screen and (max-width: 551px) {
  .content .close-btn::before {
    top: cacl(2.1875)vw;
    left: cacl(-0.625)vw;
  }
}
.content .close-btn::after {
  top: 0.8333333333vw;
  left: -0.2604166667vw;
  transform: rotate(-45deg);
}
@media only screen and (max-width: 551px) {
  .content .close-btn::after {
    top: cacl(2.1875)vw;
    left: cacl(-0.625)vw;
  }
}
.content .content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media only screen and (max-width: 551px) {
  .content .content-wrapper {
    height: 100%;
    flex-direction: column;
  }
}
.content .content-wrapper .rate-wrapper {
  position: relative;
  width: 26.5625vw;
}
@media only screen and (max-width: 551px) {
  .content .content-wrapper .rate-wrapper {
    display: flex;
    width: 100%;
    height: cacl(55.3125)vw;
    justify-content: center;
    align-items: center;
    order: 1;
    flex-shrink: 0;
  }
}
.content .content-wrapper .info-block {
  display: flex;
  width: 27.6041666667vw;
  flex-wrap: wrap;
  align-items: flex-end;
  /* width */
  /* Track */
  /* Handle */
}
@media only screen and (max-width: 551px) {
  .content .content-wrapper .info-block {
    width: 100%;
    padding-top: cacl(6.25)vw;
    align-items: flex-start;
    order: 2;
    flex-grow: 1;
  }
}
.content .content-wrapper .info-block ::-webkit-scrollbar {
  width: 5px;
}
.content .content-wrapper .info-block ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(151, 151, 151);
  border-radius: 10px;
}
.content .content-wrapper .info-block ::-webkit-scrollbar-thumb {
  background: rgb(158, 158, 158);
  border-radius: 2px;
}
.content .content-wrapper .info-block .info-block-inner {
  direction: ltr;
}
.content .content-wrapper .info-block .info-block-inner h4 {
  display: block;
  width: 100%;
  padding-bottom: 0.5208333333vw;
  font-family: "almoni_black", sans-serif;
  font-size: 2.96875vw;
  line-height: 3.4895833333vw;
  letter-spacing: -0.059375vw;
  direction: rtl;
}
@media only screen and (max-width: 551px) {
  .content .content-wrapper .info-block .info-block-inner h4 {
    padding-bottom: cacl(4.6875)vw;
    font-size: cacl(8.75)vw;
    line-height: cacl(3.75)vw;
    letter-spacing: cacl(-0.178125)vw;
  }
}
.content .content-wrapper .info-block .info-block-inner .description {
  height: 20vw;
  overflow-y: scroll;
}
@media only screen and (max-width: 551px) {
  .content .content-wrapper .info-block .info-block-inner .description {
    height: cacl(43.75)vw;
  }
}
.content .content-wrapper .info-block .info-block-inner .description p,
.content .content-wrapper .info-block .info-block-inner .description b {
  display: block;
  width: 100%;
  padding-right: 0.5208333333vw;
  padding-bottom: 1.0416666667vw;
  direction: rtl;
  font-size: 1.1458333333vw;
  line-height: 1.6666666667vw;
}
@media only screen and (max-width: 551px) {
  .content .content-wrapper .info-block .info-block-inner .description p,
  .content .content-wrapper .info-block .info-block-inner .description b {
    padding-bottom: cacl(6.25)vw;
    padding-left: cacl(6.25)vw;
    font-size: cacl(3.4375)vw;
    line-height: cacl(5)vw;
  }
}
.content .cls {
  fill: #002adb;
}

.confirm-button {
  margin: 0 0 0 20px;
}

.middle {
  position: relative;
  margin-bottom: 40px;
  flex: 1;
  text-align: left;
}