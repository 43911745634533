@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.video-pop-up {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 25px;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
}

.content {
  position: relative;
  display: flex;
  width: 64.8958333333vw;
  height: 36.5104166667vw;
  flex-flow: column nowrap;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.content .close-btn {
  position: absolute;
  top: 1.6666666667vw;
  right: 1.484375vw;
  width: 1.796875vw;
  height: 1.796875vw;
}
.content .close-btn::before, .content .close-btn::after {
  content: "";
  position: absolute;
  width: 2.2916666667vw;
  height: 0.2083333333vw;
  background-color: #aaa;
}
.content .close-btn::before {
  top: 0.8333333333vw;
  left: -0.2604166667vw;
  transform: rotate(45deg);
}
.content .close-btn::after {
  top: 0.8333333333vw;
  left: -0.2604166667vw;
  transform: rotate(-45deg);
}
.content .video-wrapper {
  width: 100%;
  height: 100%;
}
.content .video-wrapper .youtube-player {
  display: block;
  width: 100%;
  height: 100%;
}