@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.item {
  display: flex;
  height: 31.71875vw;
  margin-bottom: 2.421875vw;
  flex-direction: row;
  align-items: stretch;
}
@media only screen and (max-width: 551px) {
  .item {
    width: 100%;
    height: auto;
    margin-bottom: cacl(13.125)vw;
    flex-wrap: wrap;
  }
}
.item .info {
  width: 21.7708333333vw;
  padding-top: 2.5520833333vw;
  overflow: hidden;
  background-color: #fff;
}
@media only screen and (max-width: 551px) {
  .item .info {
    width: 100%;
    padding-right: cacl(1.5625)vw;
    margin-top: cacl(-10.3125)vw;
  }
}
.item .info a {
  text-decoration: none;
}
.item .info .rate {
  display: flex;
  padding-bottom: 2.0833333333vw;
  justify-content: flex-end;
  overflow: hidden;
}
@media only screen and (max-width: 551px) {
  .item .info .rate {
    padding-bottom: cacl(4.6875)vw;
    justify-content: flex-start;
  }
}
.item .info .rate .rate-inner {
  position: relative;
  width: 8.59375vw;
  cursor: pointer;
}
.item .info .car-category {
  display: flex;
  margin-bottom: 1.5625vw;
  justify-content: flex-end;
}
@media only screen and (max-width: 551px) {
  .item .info .car-category {
    margin-bottom: cacl(4.375)vw;
    justify-content: flex-start;
  }
}
.item .info .car-category p {
  display: inline-block;
  padding: 0.1041666667vw 0.78125vw 0vw;
  background-color: #f05;
  font-size: 1.25vw;
  line-height: 1.5625vw;
  font-family: "almoni_black", sans-serif;
  color: #fff;
}
@media only screen and (max-width: 551px) {
  .item .info .car-category p {
    padding: cacl(0)vw cacl(3.125)vw cacl(0)vw;
    font-size: cacl(3.75)vw;
    line-height: cacl(4.6875)vw;
  }
}
.item .info .title {
  padding-bottom: 1.5625vw;
}
@media only screen and (max-width: 551px) {
  .item .info .title {
    padding-bottom: cacl(4.375)vw;
  }
}
.item .info .title p {
  text-align: left;
  font-size: 4.6875vw;
  line-height: 3.125vw;
  letter-spacing: -0.09375vw;
  font-family: "almoni_black", sans-serif;
  color: #000;
}
@media only screen and (max-width: 551px) {
  .item .info .title p {
    text-align: right;
    font-size: cacl(10.9375)vw;
    line-height: cacl(7.8125)vw;
    letter-spacing: cacl(-0.21875)vw;
  }
}
.item .info .description {
  height: 7.03125vw;
  overflow: hidden;
  text-align: left;
  font-size: 1.1458333333vw;
  line-height: 1.4583333333vw;
  font-family: "Assistant", sans-serif;
}
@media only screen and (max-width: 551px) {
  .item .info .description {
    height: cacl(9.375)vw;
    overflow: hidden;
    text-align: right;
    font-size: cacl(3.4375)vw;
    line-height: cacl(4.375)vw;
  }
}
.item .media-wrapper {
  position: relative;
  display: block;
  width: 73.0729166667vw;
  height: 31.71875vw;
}
@media only screen and (max-width: 551px) {
  .item .media-wrapper {
    width: 100%;
    height: cacl(118.125)vw;
  }
}
.item .media-wrapper .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.item .media {
  position: relative;
  width: 73.0729166667vw;
  height: 31.71875vw;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
@media only screen and (max-width: 551px) {
  .item .media {
    width: 100%;
    height: cacl(118.125)vw;
  }
}
.item .media::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 31.71875vw;
  border: 31.71875vw solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0 solid transparent;
  border-left: 2.5520833333vw solid #fff;
}
@media only screen and (max-width: 551px) {
  .item .media::after {
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: cacl(15.3125)vw;
    border: calc(100vw - cacl(15.625)vw) solid transparent;
    border-bottom: cacl(15.3125)vw solid #fff;
    border-right: 0 solid transparent;
    border-top: 0 solid transparent;
  }
}