/* stylelint-disable no-descending-specificity */
.progress-splash {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s;
}
.progress-splash.busy {
  opacity: 1;
  visibility: visible;
}
.progress-splash.client {
  position: absolute;
}
.progress-splash.client .bg {
  background-color: white;
  backdrop-filter: none;
}

.bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  background-color: #7b797c;
}

.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 20px;
  height: 20px;
  transform: translate(-50%, 0);
  animation-name: preloader-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.preloader circle {
  stroke: rgba(0, 0, 0, 0.87);
  stroke-width: 4px;
  stroke-dasharray: 45, 45;
  stroke-dashoffset: 0;
}

@keyframes preloader-animation {
  0% {
    transform: translate3d(-50%, 0, 0) scale(2) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, 0, 0) scale(2) rotate(360deg);
  }
}