@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.filters {
  position: absolute;
  bottom: -61%;
  z-index: 3;
  display: flex;
  width: 100%;
  height: 7.8125vw;
  padding-top: 1.5625vw;
  background-color: white;
}
@media only screen and (max-width: 551px) {
  .filters {
    position: relative;
    bottom: auto;
    height: auto;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.filters::after {
  position: absolute;
  bottom: -61%;
  z-index: 3;
  display: flex;
  width: 100%;
  height: 10px;
  box-shadow: 0 22px 50px 0 rgba(0, 0, 0, 0.2);
}

.filter {
  position: relative;
  width: 13.0208333333vw;
  margin-left: 2.0833333333vw;
}
@media only screen and (max-width: 551px) {
  .filter {
    width: 47%;
    margin-left: 0;
  }
}

.holder {
  position: relative;
  height: 3.3854166667vw;
  border-radius: 2.6041666667vw;
  border: solid 2px #ececec;
}
@media only screen and (max-width: 551px) {
  .holder {
    height: cacl(10)vw;
    margin-bottom: cacl(7.8125)vw;
    border-radius: cacl(5.3125)vw;
  }
}
.holder:hover {
  cursor: pointer;
}
.holder .arrowDownIcon {
  position: absolute;
  top: 1.0416666667vw;
  left: 1.3020833333vw;
  z-index: 1;
  width: 1.0677083333vw;
  height: 1.0677083333vw;
  transform: rotate(0);
  transition: transform 0.2s ease-in-out;
}
@media only screen and (max-width: 551px) {
  .holder .arrowDownIcon {
    top: cacl(2.5)vw;
    left: cacl(4.6875)vw;
    width: cacl(3.125)vw;
    height: cacl(3.125)vw;
  }
}
.holder .arrowDownIcon svg {
  width: 100%;
  height: 100%;
}
.holder.active .arrowDownIcon {
  top: 1.09375vw;
  transform: rotate(180deg);
}
@media only screen and (max-width: 551px) {
  .holder.active .arrowDownIcon {
    top: cacl(2.5)vw;
  }
}
.holder input {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 2.0833333333vw;
  padding: 0 1.6145833333vw 0 1.3020833333vw;
  margin-top: 0.5208333333vw;
  border-radius: 2.6041666667vw;
  font-family: "Heebo", sans-serif;
  color: #13155d;
  border: 0;
  outline: 0;
  font-size: 0.9375vw;
  pointer-events: none;
}
@media only screen and (max-width: 551px) {
  .holder input {
    height: 100%;
    padding: 0 cacl(5.625)vw 0 cacl(6.25)vw;
    margin-top: 0;
    border-radius: cacl(5.3125)vw;
    font-size: cacl(2.8125)vw;
  }
}
.holder input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "Heebo", sans-serif;
  color: #13155d;
  font-size: 0.9375vw;
}
.holder input::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Heebo", sans-serif;
  color: #13155d;
  font-size: 0.9375vw;
}
.holder input:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "Heebo", sans-serif;
  color: #13155d;
  font-size: 0.9375vw;
}
.holder input:-moz-placeholder {
  /* Firefox 18- */
  font-family: "Heebo", sans-serif;
  color: #13155d;
  font-size: 0.9375vw;
}

.title {
  padding: 0.8854166667vw 0.2083333333vw 0 2.1354166667vw;
  font-family: "almoni_black", sans-serif;
  font-size: 1.25vw;
  line-height: 1.42;
  color: #13155d;
}
@media only screen and (max-width: 551px) {
  .title {
    display: flex;
    width: 100%;
    padding: cacl(4.6875)vw 0 cacl(6.875)vw 0;
    justify-content: space-between;
    align-items: center;
    font-size: cacl(3.75)vw;
    line-height: cacl(5)vw;
  }
}
.title .icon-back {
  display: none;
}
@media only screen and (max-width: 551px) {
  .title .icon-back {
    display: block;
    width: cacl(4.6875)vw;
  }
}

.optionsWrap {
  position: absolute;
  top: 1.71875vw;
  width: inherit;
  height: 26.0416666667vw;
  padding: 1.71875vw 0;
  overflow: scroll;
  background-color: white;
  border: solid 2px #ececec;
  border-top: none;
  border-bottom-right-radius: 2.0833333333vw;
  border-bottom-left-radius: 2.0833333333vw;
  -webkit-box-shadow: 0 1.3020833333vw 1.0416666667vw 0vw rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 1.3020833333vw 1.0416666667vw 0vw rgba(0, 0, 0, 0.16);
  box-shadow: 0 1.3020833333vw 1.0416666667vw 0vw rgba(0, 0, 0, 0.16);
}
@media only screen and (max-width: 551px) {
  .optionsWrap {
    top: cacl(6.25)vw;
    z-index: 4;
    width: 100%;
    height: auto;
    padding: cacl(3.75)vw 0 cacl(4.6875)vw;
    border-bottom-right-radius: cacl(5.3125)vw;
    border-bottom-left-radius: cacl(5.3125)vw;
    -webkit-box-shadow: 0 cacl(7.8125)vw cacl(6.25)vw cacl(0)vw rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 cacl(7.8125)vw cacl(6.25)vw cacl(0)vw rgba(0, 0, 0, 0.16);
    box-shadow: 0 cacl(7.8125)vw cacl(6.25)vw cacl(0)vw rgba(0, 0, 0, 0.16);
  }
}
.optionsWrap .option {
  padding-right: 0.78125vw;
  font-family: "Heebo", sans-serif;
  font-size: 0.8333333333vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: normal;
  color: #13155d;
}
@media only screen and (max-width: 551px) {
  .optionsWrap .option {
    height: 100%;
    padding: cacl(1.5625)vw 0;
    margin: 0 cacl(3.125)vw 0 cacl(3.125)vw;
    font-size: cacl(2.8125)vw;
  }
}
@media only screen and (max-width: 551px) {
  .optionsWrap .option:first-child {
    padding: cacl(3.125)vw 0 cacl(1.5625)vw;
    border-top: 1px solid #ececec;
  }
}

.reset-icon {
  position: absolute;
  top: 1.09375vw;
  right: 0.5208333333vw;
  z-index: 1;
  width: 0.78125vw;
  height: 0.78125vw;
}
@media only screen and (max-width: 551px) {
  .reset-icon {
    top: cacl(2.8125)vw;
    right: cacl(0.625)vw;
    width: cacl(3.125)vw;
    height: cacl(3.125)vw;
  }
}
.reset-icon::before, .reset-icon::after {
  content: "";
  position: absolute;
  width: 0.7291666667vw;
  height: 0.1041666667vw;
  background-color: #aaa;
  transition: background-color 0.3s;
}
@media only screen and (max-width: 551px) {
  .reset-icon::before, .reset-icon::after {
    width: cacl(2.5)vw;
    height: cacl(0.625)vw;
  }
}
.reset-icon::before {
  top: 0.3645833333vw;
  left: 0vw;
  transform: rotate(45deg);
}
@media only screen and (max-width: 551px) {
  .reset-icon::before {
    top: cacl(1.25)vw;
    left: cacl(0)vw;
  }
}
.reset-icon::after {
  top: 0.3645833333vw;
  left: 0vw;
  transform: rotate(-45deg);
}
@media only screen and (max-width: 551px) {
  .reset-icon::after {
    top: cacl(1.25)vw;
    left: cacl(0)vw;
  }
}
.reset-icon:hover::before, .reset-icon:hover::after {
  background-color: #f05;
}